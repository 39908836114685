import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import MatchesCalendar from "../MatchesCalendar/MatchesCalendar";
import TournamentOverviewStats from "../TournamentStatistics/TournamentOverviewStats";
import TournamentOverviewStand from "../TournamentStanding/TournamentOverviewStand";
import TopScorers from "../topScorers/topScorers";
import TopAssists from "../topAssists/topAssists";
import { IDS } from "../../globalData";
import AllMedia from "../News/AllMedia";
import TwistSettings from "../../TwistSettings.json";
import { sharedComponentsContext } from "../../context/shared-context";


const TournamentOverview = ({ season_id, toLocalTab, competitionType, tournamentDetails, isLoading, isError, data }) => {
  const { seasonInfo: { activeSeason } } = useContext(sharedComponentsContext);
  const { id, sportName } = useParams();
  const sport_id = IDS.SPORTS[sportName?.toUpperCase() ?? "FOOTBALL"];
  const isFootball = sport_id === IDS.SPORTS.FOOTBALL

  return (
    <Container className="mb-3">
      {!!activeSeason.active && (
        <MatchesCalendar
          sport_id={sport_id}
          season_id={season_id}
          tournament_id={id}
        />
      )}
      <Row>
        <Col xs={12} md={isFootball ? 8 : 12}>
          {isFootball && (
            <TournamentOverviewStand
              isLoading={isLoading}
              isError={isError}
              data={data}
              toLocalTab={toLocalTab}
              tournament_id={id}
              competitionType={competitionType}
            />
          )}
          <div className="bg-black-color p-2">
            <div className="white-color fs-xm p-2">أخر الأخبار</div>
            <AllMedia
              sport_id={sport_id}
              season_id={season_id}
              tournament_id={id}
              items_count={8}
              mini={true}
              xs={6}
              md={4}
              type={1}
              latest={true}
            />
          </div>
          {TwistSettings["videos"] &&
            <div className="bg-black-color p-2">
              <div className="white-color fs-xm p-2">أحدث الفيديوهات</div>
              <AllMedia
                sport_id={sport_id}
                season_id={season_id}
                tournament_id={id}
                items_count={8}
                mini={true}
                xs={6}
                md={4}
                type={2}
                latest={true}
              />
            </div>
          }
        </Col>
        {
          isFootball &&
          <Col xs={12} md={4}>
            <TournamentOverviewStats
              sport_id={sport_id}
              season_id={season_id}
              toLocalTab={toLocalTab}
              tournament_id={id}
              isLoading={isLoading}
              isError={isError}
              data={data}
              competitionType={competitionType}
              tournamentDetails={tournamentDetails}
            />
            <TopScorers
              sport_id={sport_id}
              season_id={season_id}
              tournament_id={id}
            />
            <TopAssists
              sport_id={sport_id}
              season_id={season_id}
              tournament_id={id}
            />
          </Col>
        }
      </Row>
    </Container>
  );
};

export default TournamentOverview;
