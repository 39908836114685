import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.js';
import 'flag-icon-css/css/flag-icon.min.css';
import "font-awesome/css/font-awesome.min.css";
import './assets/css/colors.css';
import "normalize.css";
import './assets/css/fonts.css';
import "./index.css";
import './assets/css/twist-ui.css';
import './assets/css/modals.css';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from "./App";
import Layout from "./Layout";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import SharedContext from "./context/shared-context";
import ReactGA from "react-ga4";

// Google G-tag :
ReactGA.initialize("G-0R0YHZ69G7")

const queryClient = new QueryClient()
const root = createRoot(document.getElementById('root'));


root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient} >
      <Layout>
        <SharedContext>
          <App />
        </SharedContext>
      </Layout>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  </BrowserRouter>
);
