import { Children } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useState, useEffect } from 'react';
import api from '../../apis';
import { ImgURL } from '../../globalData';
import './index.css'


function Gallery({ team_id, TeamDetails: { name } }) {
    const baseSizes = [6, 3, 3];
    const sizes = [...baseSizes, ...baseSizes.reverse()];
    const [isLoadingGalleryList, setIsLoadingGalleryList] = useState(true);
    const [galleryList, setGalleryList] = useState([]);
    const [isLoadingTeamGallery, setIsLoadingTeamGallery] = useState(true);
    const [teamGallery, setTeamGallery] = useState([]);


    const handleChooseGallery = (e) => {
        const { value } = e.target;
        getTeamGalleryFn(value)
    }

    const getTeamGalleryFn = (list_id) => {
        setIsLoadingTeamGallery(true)
        api.teamGallery.getTeamGallery(list_id).then(
            res => {
                if (res?.status === 200) {
                    setTeamGallery(res?.data)
                    setIsLoadingTeamGallery(false)
                }
            }
        )
    }

    const getGalleryListFn = (team_id, signal) => {
        api.teamGallery.getGalleryList(team_id, signal)
            .then(res => {
                if (res?.status === 200) {
                    setGalleryList(res?.data)
                    setIsLoadingGalleryList(false)
                    getTeamGalleryFn(res?.data[0].id)
                }
            })
    }

    useEffect(() => {
        const abortController = new AbortController();
        getGalleryListFn(team_id, abortController.signal);
        return () => {
            abortController.abort();
        }
    }, [team_id])


    return (
        <section className="gallery">
            <Container>
                <h2 className='text-center text-white my-5'>صور النادي {name}</h2>
                <select className="select__Form form-select mb-5" style={{ cursor: "pointer" }} onChange={handleChooseGallery}>
                    {!isLoadingGalleryList && galleryList.length &&
                        Children.toArray(galleryList.map(({ id, name }) => (
                            <option value={id}>{name}</option>
                        )))
                    }
                </select>
                <Row>
                    {isLoadingTeamGallery &&
                        Children.toArray([1, 2, 3].map((_, index) => (
                            <Col lg={sizes[index % 6]}>
                                <SkeletonTheme baseColor="#202020" highlightColor="#444">
                                    <figure>
                                        <Skeleton height={220} width={'100%'} duration={3} style={{ backgroundColor: 'white' }} />
                                    </figure>
                                </SkeletonTheme>
                            </Col>

                        )))
                    }
                    {!isLoadingTeamGallery &&
                        Children.toArray(teamGallery.map(({ image }, index) => (
                            <Col lg={sizes[index % 6]}>
                                <figure>
                                    <img src={ImgURL(image)} alt='' width={`100%`} height={`250px`} />
                                </figure>
                            </Col>

                        )))
                    }
                </Row>
            </Container>
        </section>
    )
}

export default Gallery