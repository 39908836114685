import React, { useState } from "react";
import { Container } from "reactstrap";
import "react-calendar/dist/Calendar.css";
import api from '../../apis'
import TournamentsMatchesMonthsCalendar from "../../components/TournamentsMatchesMonthsCalendar";
import TournamentsMatchesList from "../../components/TournamentsMatchesList";

const todaysDateFormater = (date = new Date().toLocaleDateString("en-US")) => {
  let [month, day, year] = date.split("/")
  day = ("0" + day).slice(-2);
  month = ("0" + month).slice(-2);
  const formatedDated = `${year}-${month}-${day}`
  return formatedDated
}

function MatchesPage() {
  const [tournamentsMatches, setTournamentsMatches] = useState([]);
  const [isFetchingTournamentsMatches, setIsFetchingTournamentsMatches] = useState(true);
  const [tournamentsMatchesFailed, setTournamentsMatchesFailed] = useState(false);
  const [selectedDate, setSelectedDate] = useState(todaysDateFormater());


  return <div className="matches-page">
    <h3 className='all-competions-header text-center p-4 text-light'>
      <strong>المباريات</strong>
      <span className='competitions-overlay'></span>
    </h3>
    <Container>
      <div className='row d-flex mt-5 justify-content-center'>
        <div className='col-10 col-md-4 my-3'>
          <TournamentsMatchesMonthsCalendar
            getTournamentsMatchesAPI={api.matches.getTournamentsMatches}
            setTournamentsMatches={setTournamentsMatches}
            setIsFetchingTournamentsMatches={setIsFetchingTournamentsMatches}
            setTournamentsMatchesFailed={setTournamentsMatchesFailed}
            setSelectedDate={setSelectedDate}
          />
        </div>
        <TournamentsMatchesList
          tournamentsMatches={tournamentsMatches}
          isFetchingTournamentsMatches={isFetchingTournamentsMatches}
          tournamentsMatchesFailed={tournamentsMatchesFailed}
          todaysDate={todaysDateFormater()}
        />
      </div>
    </Container>
  </div>
}

export default MatchesPage;
