import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Container } from 'react-bootstrap';
import './TournamentStatistics.css'
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { ImgURL } from '../../globalData';
import { getServiceName, getSportRoute } from '../../Layout/helper';
import getServiceAndSport from "../../hooks/helper";

const basketballLeague = {
    free_throws: 'رميات حرة',
    pointers_2: 'نقطتان',
    pointers_3: 'ثلاثيات',
    field_goals: 'نقاط ملعوبة',
    rebounds: 'متابعات',
    timeouts: 'وقت مستقطع',
}
const egyptionLeague = {
    goals_in: 'أهداف له',
    goals_out: 'أهداف عليه',
    clean_sheet: 'شباك نظيفة',
    fouls_in: 'أخطاء عليه',
}
const otherLeagues = {
    goals_in: 'أهداف له',
    goals_out: 'أهداف عليه',
    clean_sheet: 'شباك نظيفة',
    attempts: 'محاولات على المرمى',
    dribbles: 'المراوغات',
    passes: 'تمريرات ناجحة',
    ball_win: 'استعاد الكرة',
    ball_lose: 'خسر الكرة',
    fouls_out: 'أخطاء له',
    fouls_in: 'أخطاء عليه',
}

const TourStatsDetails = ({
    isLoading,
    isError,
    data,
    tournamentDetails,
}) => {
    const history = useHistory();
    const id = useParams().id;
    const { sportId } = getServiceAndSport(history.location.pathname)
    const getLeagueObject = () => {
        if (sportId === 3) return basketballLeague;
        return id === '472' ? egyptionLeague : otherLeagues;
    }
    const league = getLeagueObject();

    const handleRowClick = (serviceId, sportId, id) => {
        history.push(`/${getServiceName(serviceId)}${getSportRoute(sportId, serviceId)}/team/${id}`, { previousPathname: history.location.pathname });
    }

    console.log(data)
    return <Container>
        <div className='bg-black-color white-color fs-xs mt-3 row'>
            <table className="responsive-table tournament-statistics table-borderless bg-black-color table-striped table-dark white-color col-12">
                <thead>
                    <tr style={{ padding: '10px' }} className="text-center">
                        <th>المركز</th>
                        <th scope="col" style={{ minWidth: '130px' }} className={window.document.body.dir === 'rtl' ? 'text-right' : 'text-left'}>الفريق</th>
                        {Object.values(league).map(item => (
                            <th scope="col" className='text-nowrap' key={item}>{item} </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {isLoading ?
                        <tr>
                            <td colSpan={15} className='p-0'>
                                <LoadingSpinnner p={4} fs={'md'} />
                            </td>
                        </tr> :
                        isError || !data.length ?
                            <tr>
                                <td colSpan={15} className='p-0'>
                                    <ErrorMsg msg="لا يوجد احصائيات" p={5} fs={'lg'} />
                                </td>
                            </tr>
                            :
                            data.flat().map(
                                (team, i) =>
                                    <tr key={team.id} onClick={() => handleRowClick(tournamentDetails.services?.[0], team.sport_id, team.team_id)} className='table-clickable-row-animated text-center'>
                                        <td style={{ width: '25px' }}>{i + 1}</td>
                                        <td className={window.document.body.dir === 'rtl' ? 'text-right team-name' : 'text-left team-name'}><img src={ImgURL(team.team_logo)} style={{ width: '20px' }} alt="team logo" /><span className="mx-2 fs-xxs ">{team.team_name}</span></td>
                                        {Object.keys(league).map(item => (
                                            <td>{team[item]}</td>
                                        ))}
                                    </tr>
                            )
                    }
                </tbody>
            </table>
        </div>
    </Container>
}

export default TourStatsDetails;
