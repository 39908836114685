import { useContext } from "react";
import AllMedia from "../../components/News/AllMedia";
import LatestNews from "../../components/News/LatestNews";
import NewsSearch from "../../components/News/NewsSearch";
import TopStories from "../../components/News/TopStories";
import SportsSectionHeader from "../../components/sportsSectionHeader/sportsSectionHeader.component";
import useServiceAndSport from "../../hooks/useServiceAndSport";
import { sharedComponentsContext } from "../../context/shared-context";

const News = () => {
  const { NewsSearchQuery } = useContext(sharedComponentsContext);
  const { serviceId, sportId } = useServiceAndSport()

  return (
    <div className="container home-page">
      <div className="d-flex justify-content-center row">
        <div className="col-12">
          {/* <img src={IphoneAdd} height={200} width={"100%"} /> */}
        </div>
      </div>
      <SportsSectionHeader title="أهم الأحداث" />
      <TopStories
        sport_id={sportId}
        service_id={serviceId}
        season_id={1}
        items_count={4}
        media_type={1}
        tournament_id={null}
        media_id={null}
        team_id={null}
        player_id={null}
        top_stories={true}
        latest={null}
        popular={null}
      />

      <div className="d-flex justify-content-center mt-4">
        {/* <img src={pepsiAdd} width={"100%"} height={200} /> */}
      </div>
      <SportsSectionHeader title="أخر الأخبار" />
      <NewsSearch />
      <div className={`${NewsSearchQuery.length > 0 ? "d-none" : ""} my-2`}>
        <LatestNews
          sport_id={sportId}
          service_id={serviceId}
          season_id={1}
          items_count={9}
          media_type={1}
          latest={true}
        />
      </div>
      <hr></hr>
      <SportsSectionHeader title="الأكثر تداولا" />
      <AllMedia
        sport_id={sportId}
        service_id={serviceId}
        items_count={12}
        type={1}
        popular
        slider
      />
      <hr />
      <SportsSectionHeader title="كل الأخبار" />
      <AllMedia
        sport_id={sportId}
        service_id={serviceId}
        items_count={8}
        type={1}
      />
    </div>
  );
};


export default News;
