import { useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { VelocityTransitionGroup } from 'velocity-react';
import Lineup from '../lineup/lineup.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useLocation } from "react-router-dom";
import { faTshirt } from '@fortawesome/free-solid-svg-icons'
import './MatchLineup.css';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { ImgURL } from '../../globalData';
import { getServiceName, getSportRoute } from '../../Layout/helper';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import customPlayerImg from '../../assets/games/playstation-user.svg';
import api from '../../apis';
import { logFailedApi } from '../../globalFn';
import { useQuery } from '@tanstack/react-query';


require('velocity-animate');
require('velocity-animate/velocity.ui');

const apiFn = (matchId) => api.matches.getMatchLineUp(matchId)

const LiveMatchLineup = ({ matchInfo, matchId }) => {
    const { pathname } = useLocation();
    const { team1, team2 } = matchInfo;
    const [key, setKey] = useState('home');
    const { isLoading, isError, data = [] } = useQuery({
        queryKey: ["match-lineup", pathname],
        queryFn: () => apiFn(matchId),
        onError: () => logFailedApi("match-lineup"),
        select: ({ data }) => data,
        refetchOnWindowFocus: false,
    });

    const history = useHistory();
    const handleRowClick = (serviceId, sportId, id) => {
        history.push(`/${getServiceName(serviceId)}${getSportRoute(sportId, serviceId)}/player/${id}`, { previousPathname: history.location.pathname });
    };

    const getMainPlayers = (param) => data.find(team => team.team_id === param)

    const makeSideLineup = (param) => {
        const mainPlayers = getMainPlayers(param).pitch;
        const subsPlayers = getMainPlayers(param).subs;
        const sideLineUp = {
            حارس: [...mainPlayers.goal],
            دفاع: [...mainPlayers.defence],
            وسط: [...mainPlayers.midp1, ...mainPlayers.midp2, ...mainPlayers.midp3],
            هجوم: [...mainPlayers.attack],
            الاحتياطي: subsPlayers,
        }
        return sideLineUp
    }

    const animation = 'transition.swoopIn'
    const animationLeave = 'transition.slideUpOut'

    const getSideLine = (param) => (
        Object.keys(makeSideLineup(param))
            .map((el) =>
                <div key={el} className=' bg-black-color white-color p-1 rounded mt-2'>
                    <h3 className='border-bottom border-secondary m-3 pb-3 fs-xm '>{el}</h3>
                    {
                        makeSideLineup(param)[el].map((el, i2) =>
                            <VelocityTransitionGroup key={el.player_id} runOnMount enter={{ animation: animation, duration: i2 === 0 ? 150 : i2 * 1450 }} leave={{ animation: animationLeave, duration: 200 }}>
                                <div className='d-flex justify-content-start my-1 table-clickable-row p-2' style={{ position: 'relative' }} onClick={() => handleRowClick(el.services?.[0], el.sport_id, el.player_id)}>
                                    <FontAwesomeIcon icon={faTshirt} size="xl" />
                                    <div className='fs-xs sideline-player-tshirt-num' >
                                        {el.shirt_number}
                                    </div>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <img src={el.image ? ImgURL(el.image) : customPlayerImg} className={`${!el.image ? 'lineup__playerImage' : ''} mx-2 mini-player-image`} style={{ width: '35px' }} alt="player" />
                                        <div className='fs-sm'>{el.player_name}</div>
                                    </div>

                                </div>
                            </VelocityTransitionGroup>
                        )
                    }
                </div>
            )
    )

    return (isLoading ?
        <div className='mt-5'>
            <LoadingSpinnner fs={'md'} p={5} />
        </div> :
        !data.length || isError ?
            <ErrorMsg msg={"لا يوجد تشكيله"} p={4} m={2} />
            :
            <div>
                <div className='d-lg-none live-match-lineup-mini mt-2'>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => {
                            setKey(k)
                        }}
                        variant='tabs'
                        className='justify-content-center'
                    >
                        <Tab eventKey="home" title={<div>{getMainPlayers(team1?.id).team_name} <img src={ImgURL(getMainPlayers(team1?.id).logo)} className='mr-1' style={{ width: '25px' }} alt="logo"></img></div>}>
                            <Col xs={12} lg={5} className='d-flex justify-content-center'>
                                <Col className='live-match-lineup-field-mini col-11'>
                                    <Lineup teamAway={getMainPlayers(team2.id)} teamHome={getMainPlayers(team1.id)} home={true} />
                                </Col>
                            </Col>
                            <Col xs={12} lg={3} className='live-match-side-lineup-mini text-dark'>
                                {
                                    <div>
                                        {
                                            getSideLine(team1.id)
                                        }
                                    </div>
                                }
                            </Col>
                        </Tab>

                        <Tab eventKey="away" title={<div>{getMainPlayers(team2.id).team_name} <img src={ImgURL(getMainPlayers(team2.id).logo)} className='mr-1' style={{ width: '25px' }} alt="logo"></img></div>}>
                            <Col xs={12} lg={5} className='d-flex justify-content-center'>
                                <Col className='live-match-lineup-field-mini col-11'>
                                    <Lineup teamAway={getMainPlayers(team2.id)} teamHome={getMainPlayers(team1.id)} away={true} />
                                </Col>
                            </Col>
                            <Col xs={12} lg={3} className='live-match-side-lineup-mini text-dark'>
                                {
                                    <div>
                                        {
                                            getSideLine(team2.id)
                                        }
                                    </div>
                                }
                            </Col>
                        </Tab>
                    </Tabs>
                </div>
                <Row className='live-match-lineup text-dark justify-content-between d-none d-lg-flex'>
                    <Col sm={6} lg={3}>
                        {
                            <div>
                                {
                                    getSideLine(team1.id)
                                }
                            </div>
                        }
                    </Col>
                    <Col xs={12} lg={5}>
                        <div className='text-center my-3'>
                            <img src={ImgURL(getMainPlayers(team1.id).logo)} width="100px" alt="logo" />
                        </div>
                        <Col className='live-match-lineup-field'>
                            <Lineup teamAway={getMainPlayers(team2.id)} teamHome={getMainPlayers(team1.id)} secondary={true} />
                        </Col>
                        <div className='text-center my-3'>
                            <img src={ImgURL(getMainPlayers(team2.id).logo)} width="100px" alt="logo" />
                        </div>
                    </Col>
                    <Col sm={6} lg={3}>
                        {
                            <div>
                                {
                                    getSideLine(team2.id)
                                }
                            </div>
                        }
                    </Col>
                </Row>
            </div>
    )
}

export default LiveMatchLineup;