import React from 'react'
import PlayerInfoBar from './PlayerInfoBar';
import { Container, Row, Col } from 'react-bootstrap';
import PlayerMatches from './PlayerMatches';
import PositionField from './PlayerPosition';
import TransferHistory from './TransferHistory';
import PlayerOverviewLoader from './playerOverviewLoader';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import useServiceAndSport from '../../hooks/useServiceAndSport';

const PlayerOverview = ({ playerDetails, isFetchingPlayerDetails, PlayerDetailsFailed }) => {
  const { sportName } = useServiceAndSport();
  const isIndividualSports = ['squash', 'tennis'];

  return (
    <Container>
      {isFetchingPlayerDetails ?
        <PlayerOverviewLoader /> :
        PlayerDetailsFailed || !Object.keys(playerDetails).length ?
          <ErrorMsg p={5} fs={'lg'} msg="لايوجد معلومات" />
          :
          <>
            <PlayerInfoBar playerDetails={playerDetails} />
            {!isIndividualSports.includes(sportName) &&
              <PlayerMatches sport_id={1} team_id={playerDetails.current_team?.id} />
            }
            {!isIndividualSports.includes(sportName) &&
              <Row className='mt-3'>
                <Col xs={12} md={7}>
                  <TransferHistory />
                </Col>
                <Col xs={12} md={5}>
                  <PositionField playerPosition={playerDetails.position} positionAcronym={playerDetails.position_acronym} />
                </Col>
              </Row>}
          </>
      }
    </Container>
  )
}

export default PlayerOverview

