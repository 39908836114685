import React from 'react'
import ErrorPage from '../ErrorPage/ErrorPage';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import MatchCard from '../MatchCard/MatchCard';
import './TournamentsMatchesList.css'
import { useLocation } from 'react-router-dom';

function TournamentsMatchesList({ tournamentsMatches, isFetchingTournamentsMatches, tournamentsMatchesFailed, todaysDate }) {
  const { pathname } = useLocation()

  const renderComingAndResultMatches = (matches, tournamentTitle) => {

    const sortedMatches = matches.sort((a, b) => a.date > b.date ? 1 : -1)

    const todaysMatches = sortedMatches.filter(match => match.date.split(" ")[0] === todaysDate)
    const comingMatches = sortedMatches.filter(match => match.matchStatus !== 5 && match?.status !== "Complete" && match.date.split(" ")[0] > todaysDate);
    const resultMatches = sortedMatches.filter(match => match.matchStatus === 5 || match?.status === "Complete");

    return <>
      {
        todaysMatches.length > 0 &&
        < >
          <div className='bg-light-black-color p-2 white-color matches-list-title' data-testid="tournaments-matches-coming-title">{`مباريات اليوم ${(pathname.includes("/team") || pathname.includes("/tournament")) ? "" : ("من " + tournamentTitle)}`}</div>
          {todaysMatches.map((el) => <MatchCard el={el} key={el.id} />)}
        </>
      }
      {
        comingMatches.length > 0 &&
        < >
          <div className='bg-light-black-color p-2 white-color matches-list-title' data-testid="tournaments-matches-coming-title">{`مباريات القادمة ${(pathname.includes("/team") || pathname.includes("/tournament")) ? "" : ("من " + tournamentTitle)}`}</div>
          {comingMatches.map((el) => <MatchCard el={el} key={el.id} />)}
        </>
      }
      {
        resultMatches.length > 0 &&
        <>
          <div className='bg-light-black-color p-2 white-color matches-list-title' data-testid="tournaments-matches-results-title">{`مباريات السابقة ${(pathname.includes("/team") || pathname.includes("/tournament")) ? "" : ("من " + tournamentTitle)}`}</div>
          {resultMatches.map((el) => <MatchCard el={el} key={el.id} />)}
        </>
      }

      <hr className='mt-3' />
    </>
  }

  const handleRenderTournamentsMatches = (tournamentsMatches) => {
    return tournamentsMatches.map(
      (tournament, index) =>
        tournament.matches.length > 0 && (
          <div key={index} className='my-3' data-testid="tournaments-matches-tournament-wrapper">
            {renderComingAndResultMatches(tournament.matches, tournament.title)}
          </div>
        ),
    );
  };

  const handleWichDataToUse = () => {
    //Diffirent shape of data is given to this component as props from diffirent apis depending in the path
    const isTeamOrTournamentPage = (pathname.includes("/team") || pathname.includes("/tournament"))
    if (isTeamOrTournamentPage) {
      return tournamentsMatches
    }
    else {
      return tournamentsMatches?.data
    }
  }

  const isThereAnyTournamentsMatchesToday = (tournamentsMatches) => {
    const foundTournamentWithMatches = tournamentsMatches?.some(
      tournament => tournament.matches?.length > 0,
    );
    return foundTournamentWithMatches;
  };

  if (isFetchingTournamentsMatches) {
    return <div className='col-12 mx-auto col-md-8 white-color' data-testid="tournaments-matches-list-loading">
      <div className='my-3'>
        <div className='bg-light-black-color p-2 white-color'>
          مباريات
        </div>
        <div className='pt-3 white-color bg-black-color'>
          <LoadingSpinnner p={3} fs={"md"} />
        </div>
      </div>
    </div>
  } else if (tournamentsMatchesFailed) {
    return <div className='col-12 col-md-8' data-testid="tournaments-matches-list-error">
      <ErrorPage error="لا يوجد مباريات" />
    </div>
  } else if (!isThereAnyTournamentsMatchesToday(handleWichDataToUse())) {
    return <div className='col-12 mx-auto col-md-8 white-color' data-testid="tournaments-matches-list-empty-data">
      <div className='my-3'>
        <div className='bg-light-black-color p-2 white-color'>
          مباريات
        </div>
        <div className='p-5 white-color bg-black-color'>
          لا يوجد مباريات
        </div>
      </div>
    </div>
  } else {
    return <div className='col-12 mx-auto col-md-8 tournaments-matches-list'>
      {handleRenderTournamentsMatches(handleWichDataToUse())}
    </div>
  }
}

export default TournamentsMatchesList;