import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useParams } from "react-router-dom";
import MatchesCalendar from '../MatchesCalendar/MatchesCalendar'
import TournamentOverviewStand from '../TournamentStanding/TournamentOverviewStand'
import TopScorer from '../topScorers/topScorer'
import TeamOverviewStats from '../TeamStatistics/TeamOverviewStats'
import LoadingSpinnner from '../loadingSpinner/loadingSpinner'
import SeasonsDropdown from '../SeasonsDropdown/SeasonsDropdown';
import TwistSettings from '../../TwistSettings.json'
import AllMedia from '../News/AllMedia';
import useServiceAndSport from '../../hooks/useServiceAndSport';
import api from '../../apis';
import { useQuery } from '@tanstack/react-query';
import { logFailedApi } from '../../globalFn';

const apiFn = (params) => api.tournament.getTournamentsStanding(params);

const TeamOverview = ({ season_id, toLocalTab, TeamDetails, isFetchingTeamDetails, TeamDetailsFailed }) => {
    const id = useParams().id;
    const { sportId, serviceId } = useServiceAndSport();
    const { isLoading, isError, data = [] } = useQuery({
        queryKey: ["team-standing", sportId, season_id, id],
        queryFn: () => apiFn({ sport_id: sportId, season_id, tournament_id: id }),
        onError: () => logFailedApi("team-standing"),
        select: ({ data }) => data?.[0]?.data,
        enabled: sportId !== null && season_id !== null,
        refetchOnWindowFocus: false,
    });

    return (
        <Container>
            <SeasonsDropdown tournament_id={TeamDetails?.main_tournament?.id} />
            <MatchesCalendar sport_id={1} season_id={season_id} team_id={id} />
            <Row>
                <Col xs={12} md={8}>
                    <div className='bg-black-color p-2'>
                        <div className='white-color fs-xm'>أخر الأخبار</div>
                        <AllMedia
                            service_id={serviceId}
                            sport_id={1}
                            season_id={season_id}
                            type={1}
                            team_id={id}
                            items_count={8}
                        />
                    </div>
                    {
                        TwistSettings['videos'] && <div className='bg-black-color p-2'>
                            <div className='white-color fs-xm'>أحدث الفيديوهات</div>
                            <AllMedia
                                service_id={serviceId}
                                sport_id={1}
                                season_id={season_id}
                                type={2}
                                team_id={id}
                                items_count={8}
                            />
                        </div>
                    }
                </Col>
                <Col xs={12} md={4}>
                    <div className='mt-2 mt-md-0'>
                        {
                            isFetchingTeamDetails || TeamDetailsFailed ?
                                <div>
                                    <div className="p-2 fs-md bg-black-color white-color ">هداف الفريق</div>
                                    <LoadingSpinnner p={5} fs={'sm'} />
                                </div>
                                :
                                <TopScorer sport_id={1} season_id={season_id} tournament_id={TeamDetails.main_tournament !== null ? TeamDetails.main_tournament.id : null} team_id={id} />
                        }
                    </div>
                    {
                        isFetchingTeamDetails || TeamDetailsFailed ?
                            <div className='mt-2'>
                                <div className='bg-black-color white-color p-2'>الترتيب</div>
                                <LoadingSpinnner p={5} fs={'sm'} />
                            </div>
                            :
                            <TournamentOverviewStand
                                isLoading={isLoading}
                                isError={isError}
                                data={data}
                                toLocalTab={toLocalTab}
                                sport_id={1}
                                season_id={season_id}
                                tournament_id={TeamDetails.main_tournament !== null ? TeamDetails.main_tournament.id : null}
                                team_id={id} />
                    }
                    <TeamOverviewStats sport_id={1} season_id={season_id} tournament_id={null} team_id={id} toLocalTab={toLocalTab} />
                </Col>
            </Row>
        </Container>
    )
}

export default TeamOverview;
