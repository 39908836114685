import React, { useContext } from "react";
import AllMedia from "../../components/News/AllMedia";
import SportsSectionHeader from "../../components/sportsSectionHeader/sportsSectionHeader.component";
import useServiceAndSport from "../../hooks/useServiceAndSport";
import SpecialMedia from "../../components/SpecialMedia";
import SectionMediaList from "../Section/SectionMediaList";
import { IDS } from "../../globalData";
import TwistSettings from "../../TwistSettings.json"
import { sharedComponentsContext } from "../../context/shared-context";

const Videos = () => {
  const { seasonInfo: { activeSeason } } = useContext(sharedComponentsContext);
  const { serviceId, sportId } = useServiceAndSport();

  return (
    <div className="container home-page">
      <div className="d-flex justify-content-center row">
        <div className="col-12">
          {/* <img src={IphoneAdd} height={200} width={"100%"} /> */}
        </div>
      </div>
      {serviceId === IDS.SERVICES.AFRICA && TwistSettings["media-sections"] && (
        <>
          <SectionMediaList sectionName="adelSaad" />
          <SectionMediaList sectionName="africaStories" />
        </>
      )}
      <SportsSectionHeader title="فيديوهات متميزة" />
      <AllMedia
        sport_id={sportId}
        service_id={serviceId}
        season_id={activeSeason.id}
        items_count={4}
        type={2}
        tournament_id={null}
        media_id={null}
        team_id={null}
        player_id={null}
        top_stories={true}
        latest={null}
        popular={null}
      />

      <div className="d-flex justify-content-center mt-4">
        {/* <img src={pepsiAdd} width={"100%"} height={200} /> */}
      </div>
      <SportsSectionHeader title="أحدث الفيديوهات" />
      <AllMedia
        sport_id={sportId}
        service_id={serviceId}
        season_id={activeSeason.id}
        items_count={8}
        type={2}
        tournament_id={null}
        media_id={null}
        team_id={null}
        player_id={null}
        top_stories={null}
        latest={true}
        popular={null}
      />
      <hr />
      <SportsSectionHeader title="الأكثر تداولا" />
      <AllMedia
        sport_id={sportId}
        service_id={serviceId}
        season_id={activeSeason.id}
        items_count={12}
        type={2}
        tournament_id={null}
        media_id={null}
        team_id={null}
        player_id={null}
        top_stories={null}
        latest={null}
        popular={true}
      />
      <hr />
      {
        (serviceId === IDS.SERVICES.LOCAL || serviceId === IDS.SERVICES.INTERNATIONAL) &&
        TwistSettings["media-sections"] &&
        <SpecialMedia />
      }
      {
        serviceId === IDS.SERVICES.OTHERSPORTS && <>
          <SportsSectionHeader title="حلم الفراعنة" />
          <AllMedia items_count={9} type={2} section_id={27} sport_id={sportId} service_id={serviceId} />
        </>
      }
      <SportsSectionHeader title="كل الفيديوهات" />
      <AllMedia
        sport_id={sportId}
        service_id={serviceId}
        season_id={activeSeason.id}
        items_count={12}
        type={2}
        tournament_id={null}
        media_id={null}
        team_id={null}
        player_id={null}
        top_stories={null}
        latest={null}
        popular={null}
      />
    </div>
  );
};


export default Videos;
